var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-text',{staticClass:"pa-0"},[_c('v-data-table',{attrs:{"must-sort":"","headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.itemsCount,"loading":_vm.loading,"items-per-page":10},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"primary","target":"_blank","to":("/medicoes/" + (item.id))}},[_c('v-icon',[_vm._v("mdi-eye")])],1)]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.satellitesDataType[item.type])+" ")]}},{key:"item.paddock.code",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.paddock.code)+" ")]}},{key:"item.captured_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFormat")(new Date(item.captured_at),'DD/MM/YYYY'))+" ")]}},{key:"item.ms_adjusted",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("number:decimals")(item.ms_adjusted,0))+" kg/piq ")]}},{key:"item.em",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("number:decimals")(item.em,0))+" kcal/kg ")]}},{key:"item.pb",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("percent")(item.pb,1))+" ")]}},{key:"item.fdn",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("percent")(item.fdn,1))+" ")]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }