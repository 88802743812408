<script>
import { SATELLITES_DATA_ADVANCED_PAGINATE } from '@/graphql/queries'
import { satellitesDataType } from '@/utils/enums';
import set from 'lodash/set'
import { mapState } from 'vuex';

export default {
  data() {
    return {
      satellitesDataType,
      loading: false,
      items: [],
      itemsCount: 0,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['captured_at'],
        sortDesc: [true],
      },

      headers: [
        { text: 'Ajuste', value: 'id', align: 'center', sortable: false, width: 70 },
        { text: 'Fazenda', value: 'paddock.module.farm.name' },
        { text: 'Módulo/Piquete', value: 'paddock.code' },
        { text: 'Tipo de coleta', value: 'type' },
        { text: 'Data', value: 'captured_at' },
        { text: 'Matéria seca', value: 'ms_adjusted', align: 'right' },
        { text: 'Energia metabolizável', value: 'em', align: 'right' },
        { text: 'Proteína bruta', value: 'pb', align: 'right' },
        { text: 'Fibra detergente neutro', value: 'fdn', align: 'right' },
      ],
    }
  },

  computed: {
    ...mapState('dashboard', ['filter']),
    itemsLimited() {
      return this.items.slice(this.start, this.perPage+this.start);
    },

    startHeight() {
      return this.start * this.rowHeight - 32;
    },

    endHeight() {
      return this.rowHeight * (this.items.length - this.start);
    }
  },

  watch:{
    filter() {
      this.fetch()
    },

    options: {
      handler () {
        this.fetch()
      },

      deep: true,
    },
  },

  methods: {
    async fetch() {
      this.loading = true
      try{
        const filter = this.filter
        if (!filter.farmId || !filter.startAt || !filter.endAt) {
          this.loading = false
          return
        }

        const { sortBy, sortDesc, page, itemsPerPage } = this.options
        const filterParsed = {
          limit: itemsPerPage,
          offset: (page - 1) * itemsPerPage,
          orderBy: sortBy.map((item, index) => {
            let sort = {}
            set(sort, item, sortDesc[index] ? 'desc' : 'asc');
            return sort
          })
        }
        const where = {}
        where.farm_id = { _eq: filter.farmId }
        where.captured_at = { _gte: filter.startAt, _lte: filter.endAt }
        if(filter.moduleId) where.module_id = { _eq: filter.moduleId }
        if(filter.paddockIds && filter.paddockIds.length > 0) where.paddock_id = { _in: filter.paddockIds }
        filterParsed.where = where

        const { data } = await this.$apollo.query({ query: SATELLITES_DATA_ADVANCED_PAGINATE, variables: filterParsed })
        this.items = data.list
        this.itemsCount = data.count.aggregate.count
      } catch (e) {
        console.error(e)
      }
      this.loading = false
    }
  }
}
</script>

<template>
  <v-card>
    <v-card-text class="pa-0">
      <v-data-table
        must-sort
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="itemsCount"
        :loading="loading"
        :items-per-page="10"
      >
        <template v-slot:[`item.id`]="{ item }">
          <v-btn icon color="primary" target='_blank' :to="`/medicoes/${item.id}`">
            <v-icon>mdi-eye</v-icon>
          </v-btn>
        </template>
        <template v-slot:[`item.type`]="{ item }">
          {{ satellitesDataType[item.type] }}
        </template>
        <template v-slot:[`item.paddock.code`]="{ item }">
          {{ item.paddock.code }}
        </template>
        <template v-slot:[`item.captured_at`]="{ item }">
          {{ new Date(item.captured_at) | dateFormat('DD/MM/YYYY') }}
        </template>
        <template v-slot:[`item.ms_adjusted`]="{ item }">
          {{ item.ms_adjusted | number:decimals(0) }} kg/piq
        </template>
        <template v-slot:[`item.em`]="{ item }">
          {{ item.em | number:decimals(0) }} kcal/kg
        </template>
        <template v-slot:[`item.pb`]="{ item }">
          {{ item.pb | percent(1) }}
        </template>
        <template v-slot:[`item.fdn`]="{ item }">
          {{ item.fdn | percent(1) }}
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>
